import U from './lib-utils';
import css_style from '!!raw-loader!!!sass-loader!./templates/lib-toast/style.scss';
import {gem as GEM} from './lib-event';
let styles_to_install = [];
//load all scss themes
let toastThemes = require.context('!!raw-loader!!!sass-loader!./templates/lib-toast/theme', false, /\.scss/);
toastThemes.keys().forEach(function (key) {
    var m = /^.\/(.*)\.scss/.exec(key);
    if (m) {
        styles_to_install.push(toastThemes(key).default);
    }
});
//load all css themes
toastThemes = require.context('!!raw-loader!!./templates/lib-toast/theme', false, /\.css/);
toastThemes.keys().forEach(function (key) {
    var m = /^.\/(.*)\.css/.exec(key);
    if (m) {
        styles_to_install.push(toastThemes(key).default);
    }
});
let stylesInstalled = false;
const EVENT_ID = 'LIBTOAST2bab9a6c23b14c19b6457ec0e951cdaeae0df37cff474830a25c86cf1e3aa8ee';
function prepend_styles() {
    if (!stylesInstalled) {
        let headers = document.getElementsByTagName('head');
        let header_element = null;
        if (headers.length) {
            header_element = headers.item(0);
        } else {
            header_element = document.documentElement;
        }
        let themeCss = document.createElement('style');
        themeCss.type = 'text/css';
        themeCss.id = 'lib-toast-themes';
        themeCss.innerText = styles_to_install.join('\n');
        header_element.prepend(themeCss);
        let mainCss = document.createElement('style');
        mainCss.type = 'text/css';
        mainCss.id = 'lib-toast-frame-style';
        mainCss.innerText = css_style;
        header_element.prepend(mainCss);
        stylesInstalled = true;
    }
}


function append(node) {
    var bodys = document.getElementsByTagName('body');
    if (bodys.length) {
        bodys.item(0).appendChild(node);
    }
}


function toast() {
    return (toast.is(this) ? this.init : toast.F).apply(this, Array.prototype.slice.call(arguments));
}

let TP = U.fixup_constructor(toast).prototype;

function defaultOptions() {
    return {
        text: 'toast',
        to: 5000,
        theme: 'default',
        callback: null,
        tag: null
    };
}

TP.options = null;
TP.handle = null;
TP._to = null;
TP.instance_id = null;
TP.init = function (options, _to, _theme, _callback, _tag) {
    this.instance_id = ['lib-toast-', U.get_uuid()].join('');
    this.options = defaultOptions();
    if (U.isObject(options)) {
        let ops = U.safe_object(options);
        for (let k in ops) {
            if (Object.prototype.hasOwnProperty.call(ops, k)) {
                if (Object.prototype.hasOwnProperty.call(this.options, k)) {
                    this.options[k] = ops[k];
                }
            }
        }
    } else if (U.is_string(options)) {
        this.options.text = U.NEString(options, null);
        this.options.to = U.IntMoreOr(_to, 0, null);
        this.options.theme = U.NEString(_theme, null);
        this.options.callback = U.isCallable(_callback) ? _callback : null;
        this.options.tag = U.NEString(_tag, null);
    }
    this.normaliseOptions();
    prepend_styles();
    this.show();
    return this;
};

TP.normaliseOptions = function () {
    let so = defaultOptions();
    this.options.text = U.NEString(this.options.text, so.text).replace(/\n/g, '<br/>');
    this.options.theme = U.NEString(this.options.theme, so.theme);
    this.options.to = U.intMoreOr(this.options.to, 0, so.to);
    this.options.callback = U.selectCallable(this.options.callback, so.callback);
    this.options.tag = U.NEString(this.options.tag, 'default');
};

TP.show = function () {
    this.init_handle();
    append(this.handle);
    this.handle.classList.add('lib-toast-handle-visible');
    GEM().run(EVENT_ID);//this will hide others
    GEM().on(EVENT_ID, this, this.onEvent);
    return this;
};


TP.onEvent = function () {
    this.remove();
};


TP.init_handle = function () {
    this.handle = document.createElement('div');
    this.handle.classList.add('lib-toast-handle');
    this.handle.classList.add('lib-toast-theme-' + this.options.theme);
    this.handle.id = this.instance_id;
    let inner = document.createElement('div');
    inner.id = this.instance_id + '-T';
    inner.classList.add('lib-toast-content');
    inner.innerHTML = this.options.text;
    this.handle.appendChild(inner);
    this.handle.setAttribute('role', 'alert');
    this.handle.setAttribute('aria-describedby', this.instance_id + '-T');
    this._to = window.setTimeout(() => this.remove(), this.options.to);
};

TP.remove = function () {
    if (this._to) {
        window.clearTimeout(this._to);
        this._to = null;
    }
    GEM().remove(EVENT_ID, this, this.onEvent);
    this.handle.classList.remove('lib-toast-handle-visible');
    this.handle.setAttribute('role','none');
    window.setTimeout(() => {
        this.handle.remove();
        this.handle = null;
    }, 5000);
    //remove node with delay, but run callback immidiently
    if (U.isCallable(this.options.callback)) {
        try {
            this.options.callback(this.options.tag, this);
        } catch (e) {
            //
        }
    }
};


export {toast};